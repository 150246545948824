import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './AutocompleteAddress.css';
const AutocompleteAddress = ({onPlaceSelect,onValueChange,value, disabled=false,}) => {
  const [address, setAddress] = useState(value ? value.label : '');
  const [data, setData] = useState(value ? value : {});

  const handleSelect = async (selectedAddress, placeId) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const selectedPlace = {
        value: {
          place_id: data.placeId ? data.placeId : placeId,
          description: data.description ? data.description : selectedAddress,
          latitude: latLng.lat, 
          longitude: latLng.lng,
        },
        label: selectedAddress,
      };

      onPlaceSelect(selectedPlace);
      onValueChange(selectedPlace);
      setAddress(selectedAddress);
    } catch (error) {
      console.error("Erreur lors de la sélection de l'adresse :", error);
    }
  };

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={(address, placeId) => handleSelect(address, placeId)} 
    >  
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative', width: '100%' }}> {/* Ensure parent has relative positioning */}
          <input
            {...getInputProps({
              className: 'location-search-input',
              disabled: disabled,
            })}
          
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Chargement...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item suggestion-item--active'
                : 'suggestion-item';
              const key = suggestion.placeId;
            
              return (
                <div
                  key={key}
                  {...getSuggestionItemProps(suggestion, { className })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div> 
      )}
    </PlacesAutocomplete>
  );
};

export default AutocompleteAddress;
