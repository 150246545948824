import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GradientText } from '../PasserCommande/PasserCommande.styles';
import Container from '../../Components/LayoutClient/container.component';
import { getClientStats } from '../../api/clients';
import Chart from 'react-apexcharts';

function ClientStats() {
    const { _id } = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const [data, setData] = useState(null);
    const handleFilterChange = async () => {
        setChargementEnCours(true);
        try {
            const response = await getClientStats(_id, token);
            setData(response);
        } catch (error) {
            console.error('Erreur lors de la récupération des statistiques:', error);
        } finally {
            setChargementEnCours(false);
        }
    };

    useEffect(() => {
        handleFilterChange();
    }, [_id, token]);

    const combinedLabels = Array.from(
        new Set([
            ...(data?.caMensuel || []).map(item => `${item._id.mois}/${item._id.annee}`),
            ...(data?.caRemboursement || []).map(item => `${item._id.mois}/${item._id.annee}`),
            ...(data?.caRemboursementEnCours || []).map(item => `${item._id.mois}/${item._id.annee}`),
            ...(data?.caRemboursementEchoue || []).map(item => `${item._id.mois}/${item._id.annee}`),

        ])
    );

    const pieOptions = {
        chart: {
            type: 'pie'
        },
        labels: ['En cours', 'Non démarrées', 'Annulées', 'Terminées'],
        colors: ['#36A2EB', '#FFCE56', '#F44336', '#60a656'],
        legend: {
            position: 'bottom'
        },
        title: {
            text: 'Répartition des Commandes par Statut',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        }
    };
    const pieOptionsPayment = {
        chart: {
            type: 'pie'
        },
        labels: ['Payées', 'Non Payées'],
        colors: ['#60a656', '#F44336'],
        legend: {
            position: 'bottom'
        },
        title: {
            text: 'Répartition des Commandes par Statut',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        }
    };
    const pieSeriesPayment = [data?.stats[0].globalStats[0].totalPayerCount || 0, data?.stats[0].globalStats[0].totalNonPayerCount || 0]; 

    const pieSeries = [
        data?.stats[0].globalStats[0]?.totalDemarrerCount || 0,
        data?.stats[0].globalStats[0]?.totalNonDemarrerCount || 0,
        data?.stats[0].globalStats[0]?.totalAnnulerCount || 0,
        data?.stats[0].globalStats[0]?.totalTerminerCount || 0
    ];

    const barOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: combinedLabels,
            title: {
                text: 'Mois/Année'
            }
        },
        yaxis: {
            title: {
                text: 'Dépenses mensuelles (en euros)'
            },
            labels: {
                formatter: value => `${value} €`
            }
        },
        title: {
            text: 'Dépenses Mensuelles',
            align: 'center',
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%'
            }
        },
        colors: ['#4caf50', '#2196f3','#ff9800','#F44336'],
        dataLabels: {
            enabled: false
        }
    };

    const barSeries = [
        {
            name: 'Dépenses de paiement',
            data: combinedLabels.map(label => {
                const item = data?.caMensuel?.find(item => `${item._id.mois}/${item._id.annee}` === label);
                return item ? item.totalCA : 0;
            })
        },
        {
            name: 'Dépenses de remboursement reçues',
            data: combinedLabels.map(label => {
                const item = data?.caRemboursement?.find(item => `${item._id.mois}/${item._id.annee}` === label);
                return item ? item.totalCA : 0;
            })
        },
        {
            name: 'Dépenses de remboursement en cours',
            data: combinedLabels.map(label => {
                const item = data?.caRemboursementEnCours?.find(item => `${item._id.mois}/${item._id.annee}` === label);
                return item ? item.totalCA : 0;
            })
        },
        {
            name: 'Dépenses de remboursement échoués',
            data: combinedLabels.map(label => {
                const item = data?.caRemboursementEchoue?.find(item => `${item._id.mois}/${item._id.annee}` === label);
                return item ? item.totalCA : 0;
            })
        }
    ];
    const stackedBarData = {
        series: [
            {
                name: 'Démarré',
                data: data?.stats[0].groupedStats?.map(cmd => cmd?.demarrerCount) || [0],
                color: '#36A2EB',
            },
            {
                name: 'Non Démarré',
                data: data?.stats[0].groupedStats?.map(cmd => cmd?.nonDemarrerCount) || [0],
                color: '#FFCE56',
            },
            {
                name: 'Annulé',
                data: data?.stats[0].groupedStats.map(cmd => cmd?.annulerCount) || [0],
                color: '#F44336',
            },
            {
                name: 'Terminé',
                data: data?.stats[0].groupedStats?.map(cmd => cmd?.terminerCount) || [0],
                color: '#60a656',
            },
        ],
    };

    const stackedBarOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            
            categories: data?.stats[0].groupedStats?.map(item => `${item?.mois}/${item?.annee}`)|| ['Aucune donnée'],

        },
        yaxis: {
            title: {
                text: 'Nombre de commandes',
            },
            labels: {
                formatter: (val) => val,
            },
        },
        title: {
            text: 'Statistiques des Commandes Mensuelles',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
    };

    return (
        <Container>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    paddingBottom: '4rem'
                }}
            >
                <GradientText>{'Statistiques '.toUpperCase()}</GradientText>
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '1rem',
                    marginBottom: '2rem',
                    width: '100%',
                    fontFamily: 'Montserrat',
                    paddingLeft: '2rem',
                    paddingRight: '2rem'
                }}
            >
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses de paiement</h4>
                    <p>{data?.caMensuel.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements réussis</h4>
                    <p>{data?.caRemboursement.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements en cours</h4>
                    <p>{data?.caRemboursementEnCours?.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements échoués</h4>
                    <p>{data?.caRemboursementEchoue?.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '1rem',
                    width: '100%',
                    fontFamily: 'Montserrat',
                    paddingLeft: '2rem',
                    paddingRight: '2rem'
                }}
            >
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Chart options={pieOptions} series={pieSeries} type="pie" height={350} />
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Chart options={pieOptionsPayment} series={pieSeriesPayment} type="pie" height={350} />
                </div>
            </div>
            <div
                style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                    
                }}
            >
                <Chart options={barOptions} series={barSeries} type="bar" height={400} />
            </div>
            {data &&
            <div
                style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '2rem',
                    marginRight: '2rem',
                    marginBottom:'4rem'
                    
                }}
            >
                <Chart
                            options={stackedBarOptions}
                            series={stackedBarData.series}
                            type="bar"
                            height={350}
                        />
            </div>
           }
           
        </Container>
    );
}

export default ClientStats;
