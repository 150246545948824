import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTransporterStats } from '../../api/transporteurs';
import { GradientText } from '../PasserCommande/PasserCommande.styles';
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter';
import ApexCharts from 'react-apexcharts';
import Chart from 'react-apexcharts';

function TransporterStats() {
    const { _id } = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);
    const [chargementEnCours, setChargementEnCours] = useState(false);
    const [caMensuel, setCaMensuel] = useState([]);
    const [stats, setStats] = useState([]);
   
    const handleFilterChange = async () => {
        setChargementEnCours(true);
        try {
         
            const response = await getTransporterStats(_id, token);
            setCaMensuel(response.caMensuel);
            setStats(response.stats);
        } catch (error) {
            console.error('Erreur lors de la récupération des statistiques:', error);
        } finally {
            setChargementEnCours(false);
        }
    };
    

    useEffect(() => {
        handleFilterChange();
    }, [_id, token]);

   
    const barData = {
        options: {
            chart: {
                id: 'monthly-revenue',
                type: 'bar',
                height: 350,
            },
            xaxis: {
                categories: caMensuel.map(item => `${item.month}/${item.year}`),
            },
            yaxis: {
                title: {
                    text: 'Chiffre d\'affaire mensuel (en euros)',
                },
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value} €`,
                },
            },
        },
        series: [
            {
                name: 'Chiffre d\'affaire mensuel',
                data: caMensuel.map(item => item.totalPrix),
            },
        ],
    };

    const pieSeries = [
        stats[0]?.globalStats[0]?.totalDemarrerCount || 0,
        stats[0]?.globalStats[0]?.totalNonDemarrerCount || 0,
        stats[0]?.globalStats[0]?.totalAnnulerCount || 0,
        stats[0]?.globalStats[0]?.totalTerminerCount || 0
    ];
    const pieOptions = {
        chart: {
            type: 'pie'
        },
        labels: ['En cours', 'Non démarrées', 'Annulées', 'Terminées'],
        colors: ['#36A2EB', '#FFCE56', '#F44336', '#60a656'],
        legend: {
            position: 'bottom'
        },
        title: {
            text: 'Répartition des Commandes par Statut',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        }
    };
    const stackedBarData = {
        series: [
            {
                name: 'Démarré',
                data: stats[0]?.groupedStats?.map(cmd => cmd?.demarrerCount) || [0],
                color: '#36A2EB',
            },
            {
                name: 'Non Démarré',
                data: stats[0]?.groupedStats?.map(cmd => cmd?.nonDemarrerCount) || [0],
                color: '#FFCE56',
            },
            {
                name: 'Annulé',
                data: stats[0]?.groupedStats.map(cmd => cmd?.annulerCount) || [0],
                color: '#F44336',
            },
            {
                name: 'Terminé',
                data: stats[0]?.groupedStats?.map(cmd => cmd?.terminerCount) || [0],
                color: '#60a656',
            },
        ],
    };

    const stackedBarOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            
            categories: stats[0]?.groupedStats?.map(item => `${item?.mois}/${item?.annee}`)|| ['Aucune donnée'],

        },
        yaxis: {
            title: {
                text: 'Nombre de commandes',
            },
            labels: {
                formatter: (val) => val,
            },
        },
        title: {
            text: 'Statistiques des Commandes Mensuelles',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
    };

    return (
        <LayoutTransporter>
            <div 
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    paddingBottom: '4rem',
                }}
            >
                <GradientText>{'Statistiques '.toUpperCase()}</GradientText>

            </div>

                <div 
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)', 
                        gap: '1rem',
                        marginBottom: '2rem',
                        width: '100%',
                        fontFamily: 'Montserrat',
                        paddingLeft: '2rem',
                        paddingRight: '2rem'
                    }}
                >
                    <div 
                        style={{
                            backgroundColor: '#f0f0f0',
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'center',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        }}>
                        <h4>Chiffre d'affaires</h4>
                        <p>{caMensuel.reduce((sum, item) => sum + item.totalPrix, 0)} €</p>
                    </div>
                </div>

                <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '1rem',
                    width: '100%',
                    fontFamily: 'Montserrat',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    marginBottom:'4rem',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Chart options={pieOptions} series={pieSeries} type="pie" height={350} />
                </div>
                
            </div>

                <div 
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        fontFamily: 'Montserrat',
                        marginBottom:'4rem',
                        marginLeft:'1rem',
                        marginRight:'1rem' 
                    }}
                >
                <ApexCharts options={barData.options} series={barData.series} type="bar" height={350} />
                </div>
                {stats &&
            <div
                style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginBottom:'4rem',
                    marginLeft:'1rem',
                    marginRight:'1rem'   
                }}
            >
                <Chart
                            options={stackedBarOptions}
                            series={stackedBarData.series}
                            type="bar"
                            height={350}
                        />
            </div>
           }
        </LayoutTransporter>
    );
}

export default TransporterStats;
