import React ,{useState} from 'react';
import contact from '../../assets/img/contact.png';
import CommonHeader from '../../Components/CommonHeader/common-header.component';
import { useForm } from "react-hook-form";
import { sendContactData } from '../../api/auth';
import toast from 'react-hot-toast'
import Footer from '../../Components/Footer/footer.component'



function Contact() {
    const { register, handleSubmit, formState: { errors },reset } = useForm();
    const [loading,setLoading]=useState(false)
    const onSubmit = async(data) => {
        setLoading(true)
        try {
            const res=await sendContactData(data)
            toast.success(res.message, {
                style: {
                    fontFamily: 'Montserrat',
                    },
            })
            reset()
        } catch (error) {
            toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
        }finally{
            setLoading(false)
        }
    };

    return (
        <div>
            <CommonHeader />
            <div style={{
                backgroundColor: '#f8f9fa', 
                paddingBottom:'4rem',
                paddingTop:'4rem'
            }}>
            <div style={{ 
                display: 'flex',
                justifyContent: 'center',
            }}>
                {/* Section globale avec bordure */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        overflow: 'hidden',
                        border: '1px solid #ddd',
                        maxWidth: '900px',
                        width: '100%',
                        fontFamily:'Montserrat' ,

                    }}
                >
                    {/* Image sur la gauche */}
                    <div
                      style={{
                        position: 'relative',
                        flex: 1.5, // Augmenter la proportion de l'image
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        {/* Superposition transparente */}
                        <div
                            style={{
                                position: 'absolute', 
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                                zIndex: 1, 
                            }}
                        ></div>

                        {/* Image */}
                        <img
                            src={contact}
                            alt="Delivery worker"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                maxWidth: '800px',
                                objectFit: 'cover',
                                borderRight: '1px solid #ddd',
                                position: 'relative', 
                                zIndex: 0, 
                            }}
                        />
                    </div>

                    {/* Formulaire sur la droite */}
                    <div style={{ flex: 2, padding: '2rem' }}>
                        <h2
                            style={{
                                marginBottom: '1rem',
                                fontSize: '1.8rem',
                                color: '#333',
                                textAlign: 'center',
                            }}
                        >
                            Contactez-nous
                        </h2>
                        <p
                            style={{
                                marginBottom: '2rem',
                                fontSize: '1rem',
                                color: '#666',
                                textAlign: 'center',
                            }}
                        >
                            Nous restons à votre disposition pour toute information complémentaire.
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                           
                        <div>
                                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold',fontSize: 15,color:"#333"}}>
                                    Nom & Prénom
                                </label>
                                <input
                                    type="text"
                                    placeholder="Votre nom & prénom"
                                    {...register("fullName", { required: "Veuillez indiquer votre nom." })}
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        borderWidth:1
                                    }}
                                />
                                {errors.fullName && <span style={{ color: 'red', fontSize: '0.9rem' }}>{errors.fullName.message}</span>}
                            </div>

                            <div>
                                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold',fontSize: 15,color:"#333"}}>
                                    Adresse email
                                </label>
                                <input
                                    type="email"
                                    placeholder="Adresse email"
                                    {...register("email", {
                                        required: "Veuillez indiquer l'adresse e-mail.",
                                        pattern: {
                                            value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
                                            message: "Adresse e-mail invalide.",
                                        },
                                    })}
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid  #ccc',
                                        borderRadius: '4px',
                                        borderWidth:1
                                    }}
                                />
                                {errors.email && <span style={{ color: 'red', fontSize: '0.9rem' }}>{errors.email.message}</span>}
                            </div>

                            <div>
                                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' ,fontSize: 15,color:"#333"}}>
                                    Téléphone
                                </label>
                                <input
                                    type="tel"
                                    placeholder="Téléphone"
                                    {...register("phone", {
                                        required: "Veuillez indiquer le numéro de téléphone.",
                                    })}
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid   #ccc',
                                        borderRadius: '4px',
                                        borderWidth:1
                                    }}
                                />
                                {errors.phone && <span style={{ color: 'red', fontSize: '0.9rem' }}>{errors.phone.message}</span>}
                            </div>

                            <div>
                                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold',fontSize: 15,color:"#333"}}>
                                    Sujet
                                </label>
                                <input
                                    type="text"
                                    placeholder="Sujet"
                                    {...register("subject", { required: "Veuillez indiquer le sujet." })}
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        borderWidth:1
                                    }}
                                />
                                {errors.subject && <span style={{ color: 'red', fontSize: '0.9rem' }}>{errors.subject.message}</span>}
                            </div>
                            <div>
                                <label style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' ,fontSize: 15,color:"#333"}}>
                                    Message
                                </label>
                                <textarea
                                    placeholder="Votre message"
                                    {...register("message", { required: "Veuillez indiquer votre message." })}
                                    style={{
                                        width: '100%',
                                        padding: '0.5rem',
                                        border: '1px solid  #ccc',
                                        borderRadius: '4px',
                                        minHeight: '100px',
                                        borderWidth:1
                                    }}
                                />
                                {errors.message && <span style={{ color: 'red', fontSize: '0.9rem' }}>{errors.message.message}</span>}
                            </div>


                            <button
                                type="submit"
                                disabled={loading}
                                style={{
                                    padding: '0.75rem',
                                    background:'#51C7F2',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    padding: '1rem 4rem',
	                                fontWeight: 'bold'
                                }}
                            >
                                Envoyer
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Contact;
