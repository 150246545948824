import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
import { softDeleteWaypoint ,getPointsPassageForMission} from '../../api/commande';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
// Fonction pour formater l'heure
const formatHeure = (heure) => {
    // Vérifier si heure est falsy ou ne contient pas ':'
    if (!heure || !heure.includes(':')) return ""; 

    const [hours, minutes] = heure.split(':').map(Number);
    
    // Gérer les cas où hours ou minutes peuvent être NaN
    if (isNaN(hours) || isNaN(minutes)) return "";

    // Retourner la durée formatée
    return hours === 0 ? `${minutes}min` : `${hours}h${minutes > 0 ? minutes + "min" : ""}`;
};



// Style pour le modal
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "80%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

// Styles pour les éléments du modal
const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#51C7F2',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
};

const placeStyle = {
    background: '#fff',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const labelStyle = {
    marginBottom: '8px',
    fontSize: '14px',
    color: '#838EAB',
};

const PointsPassageModal = ({ isOpen, onClose, missionId }) => {
    const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
    const [selectedPlace,setSelectedPlace]=useState(null)
    const [places,setPlaces]=useState([])
    const [loading,setLoading]=useState(false)
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector((state) => state.auth.token);
    
    const fetchPlaces=async(missionId)=>{
        setChargementEnCours(true)
        try {
           const res=await  getPointsPassageForMission(missionId,token)
           setPlaces(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
         setChargementEnCours(false)
        }
    }
    useEffect(()=>{
        if(missionId){
            fetchPlaces(missionId)
        }
    },[missionId])
    const handleDelete=async()=>{
        setLoading(true)
        try {
            const res= await softDeleteWaypoint(token,selectedPlace)
            toast.success(res.message, {
                style: {
                    fontFamily: 'Montserrat',
                    },
            })
            setPlaces([])
            await fetchPlaces(missionId)
        } catch (error) {
            toast.error(error.response?.data.error || "Une erreur s'est produite", {
                style: { fontFamily: 'Montserrat' },
            });
        }finally{
            setModalIsOpenDelete(false);
            setSelectedPlace(null);
            setLoading(false);
        }
    }
    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={()=>{
                onClose()
                setPlaces([])
            }} 
            ariaHideApp={false}
            style={modalStyles}
        >
            <div style={headerStyle}>
                <h2 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>Points Passage</h2>
                <button 
                    onClick={onClose}  
                    style={closeButtonStyle}
                >
                    X
                </button>
            </div>
            <div style={contentStyle}>
            {chargementEnCours ? (
                    <p style={{ textAlign: 'center' }}>Chargement en cours...</p>
                )
                :places?.length===0 ?
                <div  style={{ 
                    background: '#fff',
                    padding: '15px',
                    borderRadius: '8px',
                    marginBottom: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    textAlign:'center'
                }} >Il n'y a pas de points de passage à afficher</div>
                :
                places.map((place, index) => (
                    <div key={index} 
                    style={{
                        ...placeStyle,
                        backgroundColor: place.active ? '#fff' : '#f0f0f0',
                        color: place.active ? 'inherit' : '#a0a0a0', 
                        opacity: place.active ? 1 : 0.6 
                        }}
                    >
                        <div style={labelStyle}>
                                    <strong>Adresse :</strong> <span style={{ color: '#FF5A5F' }}>{place.adresse.label}</span>
                                    <strong style={{ marginLeft: "1rem" }}>Distance :</strong> <span style={{ color: '#FF5A5F' }}>{place.distance} Km</span>
                                    <strong style={{ marginLeft: "1rem" }}>Heure :</strong> <span  style={{ color: '#FF5A5F' }}>{formatHeure(place.heure) || '00min'}</span>
                             
                        </div>

                        {index !== 0 && (
                            <>
                                {place?.nom_destinataire && (
                                    <div style={labelStyle}>
                                        <strong>Nom du destinataire :</strong> {place.nom_destinataire}
                                        {place?.prénom_destinataire && (
                                            <>
                                                <strong style={{ marginLeft: "1rem" }}>Prénom du destinataire :</strong> {place.prénom_destinataire}
                                            </>
                                        )}
                                    </div>
                                )}

                                {place?.adresse_destinataire && (
                                    <div style={labelStyle}>
                                        <strong>Adresse du destinataire :</strong> {place.adresse_destinataire}
                                        {place?.téléphone_destinataire && (
                                            <>
                                                <strong style={{ marginLeft: "1rem" }}>Numéro de Téléphone du destinataire :</strong> {place.téléphone_destinataire}
                                            </>
                                        )}
                                    </div>
                                )}

                                {place?.email_destinateur && (
                                    <div style={labelStyle}>
                                        <strong>Email du destinataire :</strong> {place.email_destinateur}
                                    </div>
                                )}

                              
                                   
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ marginTop: '8px', fontSize: '14px', color: '#838EAB' }}>
                                        <strong>Signé :</strong> {place?.signé ? 'Oui' : 'Non'}
                                        <strong style={{ marginLeft: "1rem" }}>Signalé :</strong> {place?.signaler ? 'Oui' : 'Non'}
                                        <strong style={{ marginLeft: "1rem" }}>Activé :</strong> {place?.active ? 'Oui' : 'Non'}
                                    </div>
                                    {!place?.active &&
                                   <div
							        style={{
 								    right: 130,
								    cursor: 'pointer',
								    display: 'flex',
								    alignItems: 'center',
								    justifyContent: 'center',
							       }}
                                   onClick={()=>{
                                    setSelectedPlace(place?._id)
								    setModalIsOpenDelete(true)
                                   }}
                                   >
							        <div
								     style={{
									  height: 30,
									  width: 30,
								    }}
							        >
								    <svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									color=' #838EAB'
								    >
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
									/>
								</svg>
							    </div>
			                      </div>
                                  } 
                                 </div>

                               
                               
                            </>
                        )}
                    </div>
                ))}
            </div>
            <Modal
         isOpen={modalIsOpenDelete}
         style={{
             overlay: {
                 position: 'fixed',
                 top: 0,
                 left: 0,
                 right: 0,
                 bottom: 0,
                 backgroundColor: 'rgba(0, 0, 0, 0.45)',
             },
             content: {
                 top: '50%',
                 left: '50%',
                 right: 'auto',
                 bottom: 'auto',
                 marginRight: '-50%',
                 transform: 'translate(-50%, -50%)',
                 borderRadius: 19,
                 padding: '2rem',
             },
         }}
         contentLabel='Example Modal'
     >
         <div
             style={{
                 width: '20rem',
                 fontFamily: 'Montserrat',
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 justifyContent: 'center',
                 fontSize: 15,
                 textAlign: 'center',
             }}
         >
             <div
             style={{
                 fontWeight: 'bold',
                 fontFamily: 'Montserrat',
                 fontSize:20,
                 color: '#51C7F2',
             }}
             > Supprimer Adresse</div>
             <div
                 style={{
                     marginTop: 4,
                     width: '100%',
                 }}
             >
                 
                 <p>Êtes-vous sûr de vouloir supprimer cette adresse ? </p>

             </div>
              <div
              style={{
                 width: '100%',
                 fontFamily: 'Montserrat',
                 paddingLeft: '1rem',
                 paddingRight: '1rem',
                 marginBottom: '5px',
                 marginTop: '1rem',
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: 'space-between'
             }}>
             <button
                 onClick={handleDelete}
                 disabled={loading}
                 style={{
                     width:"150px",
                     height:"50px",
                     background: '#50F5A9',
                     border: 'none',
                     cursor: 'pointer',
                     color: 'white',
                     borderRadius: 7,
                     marginTop: 5,
                     fontFamily: 'Montserrat',
                     marginRight:10,
                     fontWeight: 'bold',
                     display: 'flex',
                      justifyContent: 'center',
                     alignItems: 'center'
                 }}

             >
             
             {loading ? (
                             <div className='loader'></div>
                         ) : (
                             'Confirmer'
                         )}
                         
             </button>
             <button
                 onClick={ () => {
                     setModalIsOpenDelete(false)
                                         
                 }}
                 style={{
                     width:"150px",
                     height:"50px",
                     background: 'red',
                     border: 'none',
                     cursor: 'pointer',
                     color: 'white',
                     borderRadius: 7,
                     marginTop: 5,
                     fontFamily: 'Montserrat',
                     fontWeight: 'bold',
                 }}
             >
                 Annuler
             </button>
             </div>
             
         </div>
    </Modal>
        </Modal>
       
    );

};

export default PointsPassageModal;
