import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GradientText } from '../PasserCommande/PasserCommande.styles';
import { getCommercialStats } from '../../api/commerciaux';
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant';
import Chart from 'react-apexcharts';

function useCommercialStats(userId, token) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await getCommercialStats(userId, token);
                setData(response);
            } catch (err) {
                setError('Erreur lors de la récupération des statistiques.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, [userId, token]);

    return { data, loading, error };
}

function CommercialStats() {
    const { _id } = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);

    const { data, loading, error } = useCommercialStats(_id, token);

    // Récupérer les clients uniques et les catégories
    const clients = useMemo(() => [...new Set(data?.caMensuel.map(entry => entry._id.clientId))], [data]);
    const categories = useMemo(() => [...new Set(data?.caMensuel.map(entry => `${entry._id.mois}/${entry._id.annee}`))], [data]);
    
    const couleursClients = ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#FF9F40', '#FF5733', '#C70039', '#900C3F', '#581845'];

    const getCouleur = (clientIndex) => couleursClients[clientIndex % couleursClients.length];

    // Préparation des données pour le graphique
    const series = useMemo(() => 
        clients.map((clientId, index) => ({
            name: clientId,
            data: categories.map(category => {
                const entry = data?.caMensuel.find(
                    e => e._id.clientId === clientId && `${e._id.mois}/${e._id.annee}` === category
                );
                return entry ? parseFloat(entry.totalCA).toFixed(2) : 0; 
            }),
            color: getCouleur(index),
        }))
    , [clients, categories, data]);
    const seriesRemboursement = useMemo(() => 
        clients.map((clientId, index) => ({
            name: clientId,
            data: categories.map(category => {
                const entry = data?.caRemboursement.find(
                    e => e._id.clientId === clientId && `${e._id.mois}/${e._id.annee}` === category
                );
                return entry ? parseFloat(entry.totalCA).toFixed(2) : 0; 
            }),
            color: getCouleur(index),
        }))
    , [clients, categories, data]);
    const seriesRemboursementEnCours = useMemo(() => 
        clients.map((clientId, index) => ({
            name: clientId,
            data: categories.map(category => {
                const entry = data?.caRemboursementEnCours.find(
                    e => e._id.clientId === clientId && `${e._id.mois}/${e._id.annee}` === category
                );
                return entry ? parseFloat(entry.totalCA).toFixed(2) : 0; 
            }),
            color: getCouleur(index),
        }))
    , [clients, categories, data]);
    const seriesRemboursementEchoués = useMemo(() => 
        clients.map((clientId, index) => ({
            name: clientId,
            data: categories.map(category => {
                const entry = data?.caRemboursementEchoue.find(
                    e => e._id.clientId === clientId && `${e._id.mois}/${e._id.annee}` === category
                );
                return entry ? parseFloat(entry.totalCA).toFixed(2) : 0; 
            }),
            color: getCouleur(index),
        }))
    , [clients, categories, data]);
    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Mois/Année',
            },
        },
        yaxis: {
            title: {
                text: 'Dépenses de Paiement Mensuelles (€)',
            },
        },
        title: {
            text: 'Dépenses de Paiement par Client',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: true,
        },
        colors: series.map((_, index) => getCouleur(index)),
    };

    const optionsRemboursement = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Mois/Année',
            },
        },
        yaxis: {
            title: {
                text: 'Dépenses de remboursement reçues Mensuelles (€)',
            },
        },
        title: {
            text: 'Dépenses de remboursement reçues par Client',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: true,
        },
        colors: seriesRemboursement.map((_, index) => getCouleur(index)),
    };
    const optionsRemboursementEnCours = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Mois/Année',
            },
        },
        yaxis: {
            title: {
                text: 'Dépenses de remboursement en cours Mensuelles (€)',
            },
        },
        title: {
            text: 'Dépenses de remboursement en cours par Client',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: true,
        },
        colors: seriesRemboursement.map((_, index) => getCouleur(index)),
    };
    const optionsRemboursementEchoués = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'Mois/Année',
            },
        },
        yaxis: {
            title: {
                text: 'Dépenses de remboursement échoués Mensuelles (€)',
            },
        },
        title: {
            text: 'Dépenses de remboursement échoués par Client',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
        dataLabels: {
            enabled: true,
        },
        colors: seriesRemboursement.map((_, index) => getCouleur(index)),
    };
    
    const paymentOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            categories: data?.stats.map(client => `${client.first_name} ${client.last_name} (${client.__id})`),
        },
        yaxis: {
            title: {
                text: 'Nombre de commandes',
            },
        },
        title: {
            text: 'Statistiques de Paiement par Client',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
        colors: ['#4CAF50', '#F44336'], 
    };
   
    
    
    const paymentData = {
        series: [
            {
                name: 'Commandes Payées',
                data: data?.stats.map(client => client.paid),
            },
            {
                name: 'Commandes Non Payées',
                data: data?.stats.map(client => client.unpaid),
            },
        ],
    };
    


    const stackedBarData = {
        series: [
            {
                name: 'Démarré',
                data: data?.stats.map(client => client.démarrer),
                color: '#2196F3',
            },
            {
                name: 'Non Démarré',
                data: data?.stats.map(client => client.nonDémarrer),
                color: '#FFCE56',
            },
            {
                name: 'Annulé',
                data: data?.stats.map(client => client.annuler),
                color: '#F44336',
            },
            {
                name: 'Terminé',
                data: data?.stats.map(client => client.terminer),
                color: '#4CAF50',
            },
        ],
    };

    const stackedBarOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            
            categories: data?.stats.map(client => `${client.first_name} ${client.last_name} (${client.__id})`),

        },
        yaxis: {
            title: {
                text: 'Nombre de commandes',
            },
            labels: {
                formatter: (val) => val,
            },
        },
        title: {
            text: 'Statistiques des commandes par client et par statut',
            align: 'center',
        },
        fill: {
            opacity: 1,
        },
    };

    // Affichage des résultats
    return (
        <LayoutCommercant>
            <div style={{
                width: '100%',
                fontFamily: 'Montserrat',
                paddingLeft: '5rem',
                paddingRight: '5rem',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    paddingTop: '2rem',
                    paddingBottom: '4rem',
                }}>
                    <GradientText>{'Statistiques '.toUpperCase()}</GradientText>
                </div>

                {loading && 
                 <div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
				</div>}
                {error && <p>{error}</p>}
               
                
                {data && (
                    <div>
                     <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '1rem',
                    marginBottom: '2rem',
                    width: '100%',
                    fontFamily: 'Montserrat',
                    paddingLeft: '2rem',
                    paddingRight: '2rem'
                }}
            >
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses de paiement</h4>
                    <p>{data?.caMensuel.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements réussis</h4>
                    <p>{data?.caRemboursement.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements en cours</h4>
                    <p>{data?.caRemboursementEnCours?.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                <div
                    style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <h4>Dépenses des remboursements échoués</h4>
                    <p>{data?.caRemboursementEchoue?.reduce((sum, item) => sum + item.totalCA, 0)} €</p>
                </div>
                </div>
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                   <Chart
                            options={stackedBarOptions}
                            series={stackedBarData.series}
                            type="bar"
                            height={350}
                        />
                    </div>
                        
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                    <Chart
                           options={paymentOptions}
                           series={paymentData.series}
                           type="bar"
                           height={350}
                        />
                    </div>
                       
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                   <Chart
                            options={options}
                            series={series}
                            type="bar"
                            height={350}
                />
                    </div>
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                   <Chart
                            options={optionsRemboursement}
                            series={seriesRemboursement}
                            type="bar"
                            height={350}
                />
                    </div>
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                   <Chart
                            options={optionsRemboursementEnCours}
                            series={seriesRemboursementEnCours}
                            type="bar"
                            height={350}
                />
                    </div>
                    <div
                    style={{
                    backgroundColor: '#f0f0f0',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    fontFamily: 'Montserrat',
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    marginBottom:'4rem'    
                   }}
                  >
                   <Chart
                            options={optionsRemboursementEchoués}
                            series={seriesRemboursementEchoués}
                            type="bar"
                            height={350}
                />
                    </div>
                    </div>
                )}
            </div>
        </LayoutCommercant>
    );
}

export default CommercialStats;
