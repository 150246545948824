import { Route, Switch } from 'react-router'
import PrivateRoute from './PrivateRoute'
import AnomalieTransport from './Admin/Plateforme/AnomalieTransport'
import Attribuer from './Admin/Plateforme/Attribuer'
import ChauffeurAccount from './Account/ChauffeurAccount'
import ClientAccount from './Account/ClientAccount'
import CloseSignature from './CloseSignature'
import Commandes from './Admin/Plateforme/Commandes'
import CommandesList from './CommandesList/CommandesList'
import ComptabiliteByDay from './ComptabiliteClient/ComptabiliteByDay'
import ComptabiliteByDayTransporter from './ComptabiliteTransporteur/ComptabiliteByDayTransporter'
import ComptabiliteByMonth from './ComptabiliteClient/ComptabiliteByMonth'
import ComptabiliteByMonthTransporter from './ComptabiliteTransporteur/ComptabiliteByMonthTransporter'
import ComptabiliteClients from './Admin/Comptabilite/ComptabiliteClients'
import ComptabiliteTransporteurs from './Admin/Comptabilite/ComptabiliteTransporteurs'
import CreateAccount from './CreateAccount/CreateAccount'
import CreateChauffeur from './CreateChauffeur/CreateChauffeur'
import CreateTransporter from './CreateTransporter/CreateTransporter'
import DetailCommandeAdmin from './Admin/Plateforme/DetailCommandeAdmin'
import EditChauffeur from './EditChauffeur/EditChauffeur'
import DetailsCommande from './DetailsCommande/DetailsCommande'
import DetailsCommandeTransporter from './DetailsCommandeTransporter/DetailsCommandeTransporter'
import EditSignalement from './Signaler/Chauffeur/EditSignalement'
import EditSignalementAdmin from './Signaler/Chauffeur/EditSignalementAdmin'
import Facturation from './Admin/Plateforme/Facturation'
import Felicitation from './Felicitation/Felicitation'
import Historique from './Historique/Historique'
import HistoriqueTransporter from './Historique/HistoriqueTransporter'
import HistoriqueChauffeur from './Historique/HistoriqueChauffeur'
import HomePage from './Home/HomePage'
import ListChauffeurs from './ListChauffeurs/ListChauffeurs'
import ListTransportsChauffeurs from './ListTransportsChauffeurs/ListTransportsChauffeurs'
import Map from './Map/Map'
import MesClients from './Admin/MesClients'
import MesTransporteurs from './Admin/Transporteurs/MesTransporteurs'
import MesTransports from './MesTransports/MesTransports'
import NewSignalement from './Signaler/Chauffeur/NewSignalement'
import NotFound from './NotFound/NotFound'
import NouveauxTransporteurs from './Admin/Transporteurs/NouveauxTransporteurs'
import PasserCommande from './PasserCommande/PasserCommande'
import PayCommande from './PayCommande/PayCommande'
import PayHop from './PayHop'
import Plateforme from './Plateforme/Plateforme'
import PublicPasserCommande from './PublicPasserCommande/PublicPasserCommande'
import React from 'react'
import Register from './Register/Register'
import RegisterTransporter from './RegisterTransporter/RegisterTransporter'
import Salon from './Admin/Plateforme/Salon'
import SignIn from './SignIn/SignIn'
import SignInV2 from './SignInV2/SignInV2'
import SignalerAdminAll from './Signaler/Chauffeur/SignalerAdminAll'
import SignalerChauffeurAll from './Signaler/Chauffeur/SignalerChauffeurAll'
import SignalerClientAll from './Signaler/Chauffeur/SignalerClientAll'
import SignalerTransporterAll from './Signaler/Chauffeur/SignalerTransporterAll'
import SingleClient from './Admin/SingleClient'
import SingleClientFacturation from './Admin/Plateforme/SingleClientFacutration'
import SingleTransportChauffeur from './SingleTransportChauffeur/SingleTransportChauffeur'

import SingleTransporter from './Admin/Transporteurs/SingleTransporter'
import Tarif from './Tarif/Tarif'
import TransporterAccount from './Account/TransporterAccount'
import VerfiyEmail from './Register/VerfiyEmail'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ResetPassword from './ForgotPassword/ResetPassword'
import ComptabiliteSingleClient from './Admin/Comptabilite/ComptabiliteSingleClient'
import ComptabiliteSingleTransporteur from './Admin/Comptabilite/ComptabiliteSingleTransporteur'
import TransporteurListChauffeur from './Admin/Transporteurs/TransporteurListChauffeur'
import SingleChauffeur from './Admin/Transporteurs/SingleChauffeur'
import NouveauxAdministrateur from './Admin/Administrateur/NouveauxAdministrateur '
import MesAdministrateur from './Admin/Administrateur/MesAdministrateur '
import AdministrateurSingle from './Admin/Administrateur/AdministrateurSingle'
import CompteAdministrateur from './Admin/Administrateur/CompteAdministrateur'
import CardSetupFormWrapper from './CardSetupForm/CardSetupFormWrapper'
import CommandeAnnulées from './CommandesAnnulées/CommandeAnnulées'
import CommandeAnnuléesTransporter from './CommandesAnnulées/CommandeAnnuléesTransporter'
import CommandeAnnuléesChauffeur from './CommandesAnnulées/CommandeAnnuléesChauffeur'
import CommandeAnnulles from './Admin/Plateforme/CommandeAnnulles'
import Terminer from './Admin/Plateforme/Terminer'
import MesClientsSupprimer from './Admin/MesClientsSupprimer'
import MesTransporteursSupprimer from './Admin/Transporteurs/MesTransporteursSupprimer'
import NouveauxCommercant from './Admin/Commerçantes/NouveauxCommercant'
import MesCommercant from './Admin/Commerçantes/MesCommercant'
import CommercantSingle from './Admin/Commerçantes/CommercantSingle'
import CreateClient from './CreateClient.js/CreateClient'
import ClientList from './ClientList/ClientList'
import ListCommande from './ListCommande/ListCommande'
import DetailsCommandeCommercant from './DetailsCommandeCommercant/DetailsCommandeCommercant'
import CommercantAccount from './Account/CommercantAccount'
import ListClient from './Admin/Commerçantes/ListClient'
import ListCommandes from './Admin/Commerçantes/ListCommandes'
import ClientAccountForCommercial from './Account/ClientAccountForCommercial'
import ComptabiliteCommercial from './Admin/Commerçantes/ComptabiliteCommercial'
import FailedCharge from './FailedCharge/FailedCharge'
import MesChauffeurs from './Admin/MesChauffeurs'
import ComptabiliteCommerciaux from './ComptabiliteCommerciaux/ComptabiliteCommerciaux'
import MesCommerciauxSupprimer from './Admin/Commerçantes/MesCommerciauxSupprimer'
import SignleClientDelete from './Admin/SignleClientDelete'
import SingleTransporterDelete from './Admin/Transporteurs/SingleTransporterDelete'
import ListChauffeurForTransporteurDelete from './Admin/Transporteurs/ListChauffeurForTransporteurDelete'
import SingleChauffeurDelete from './Admin/Transporteurs/SingleChauffeurDelete'
import SingleCommercialDelete from './Admin/Commerçantes/SingleCommercialDelete'
import SingleFailedCharge from './FailedCharge/SingleFailedCharge'
import Prelevements from './FailedCharge/Prelevements'
import Prelevement from './Prelevement/Prelevement'
import PrelevementDetails from './Prelevement/PrelevementDetails'
import DetailsSignalement from './Signaler/Chauffeur/DetailsSignalement'
import DetialsSignalementTransporteur from './Signaler/Chauffeur/DetialsSignalementTransporteur'
import DetailsSignalementChauffeur from './Signaler/Chauffeur/DetailsSignalementChauffeur'
import SingleTransportDetailsChauffeur from './SingleTransportChauffeur/SingleTransportDetailsChauffeur'
import CommerciauxComptabilite from './Admin/Comptabilite/CommerciauxComptabilite'
import AdminArchive from './Admin/Administrateur/AdminArchive'
import AdminArchiveSingle from './Admin/Administrateur/AdminArchiveSingle'
import HistoriqueChauffeurCommande from './HistoriqueChauffeur/HistoriqueChauffeurCommande'
import HistoriqueTransporteurCommande from './HistoriqueTransporteurCommande/HistoriqueTransporteurCommande'
import DemandeTransfert from '../Components/DemandeTransfert/DemandeTransfert'
import DetailsDemandeTransfert from '../Components/DemandeTransfert/DetailsDemandeTransfert'
import AttributionMissions from '../Components/AttributionMissions/AttributionMissions'
import TransportTracking from '../Components/TransportTracking/TransportTracking'
import NewSignalementMiseADispo from './Signaler/Chauffeur/NewSignalementMiseADispo'
import SignalerChauffeurAllAdresse from './Signaler/Chauffeur/SignalerChauffeurAllAdresse'
import PointsPassageMissionClient from '../Components/PointsPassages/PointsPassageMissionClient'
import SignalerClientAllAdresse from './Signaler/Chauffeur/SignalerClientAllAdresse'
import SignalerChauffeurAllMission from './Signaler/Chauffeur/SignalerChauffeurAllMission'
import SignalerClientAllCommandeMiseADispo from './Signaler/Chauffeur/SignalerClientAllCommandeMiseADispo'
import HistoriqueChauffeurMission from './HistoriqueChauffeur/HistoriqueChauffeurMission'
import DetailsChauffeur from './DetailsChauffeur/DetailsChauffeur'
import TransporterStats from './Stats/TransporterStats'
import UpdateDocument from './Admin/Transporteurs/UpdateDocument'
import MissionsClient from './Missions/MissionsClient'
import DetailsSignalementAdmin from './Signaler/Chauffeur/DetailsSignalementAdmin'
import CommercialStats from './Stats/CommercialStats'
import ClientStats from './Stats/ClientStats'
import Contact from './Contact/Contact'

function Routes() {
	return (
		<Switch>
			<Route path='/' component={HomePage} exact />
			<Route path='/contact' component={Contact} exact />
			<Route path='/commandes/new' component={PasserCommande} exact />
			<PrivateRoute path='/commandes/:id/pay' component={PayCommande} exact />

			<PrivateRoute path='/chauffeurs' component={ListChauffeurs} exact />

			<PrivateRoute
				path='/chauffeur/transports'
				component={ListTransportsChauffeurs}
				exact
			/>
			<PrivateRoute
				path='/chauffeur/historique'
				component={HistoriqueChauffeur}
				exact
			/>
			<PrivateRoute
			   path='/client/points-passage/:idMission'
			   component={PointsPassageMissionClient}
			   extact
			/>

			<Route
				path='/chauffeur/transports/:id'
				component={SingleTransportChauffeur}
				exact
			/>
			<PrivateRoute
				path='/admin/historique-tansporteur/:id'
				component={HistoriqueTransporteurCommande}
				exact
			/>
			<PrivateRoute
				path='/transports/historique-chauffeur/:id'
				component={HistoriqueChauffeurCommande}
				exact
			/>
			<PrivateRoute  
			  path='/transports/mission/historique-chauffeur/:id'
			  component={HistoriqueChauffeurMission}
			  exact
			/>
			<PrivateRoute
				path='/transporter/attribution-missions/:id'
				component={AttributionMissions}
				exact
			/>
			<PrivateRoute
			   path='/stats/transporter'
			   component={TransporterStats}
			   exact
			/>
			
			<PrivateRoute
			  path='/stats/commercial'
			  component={CommercialStats}
			/>
			<PrivateRoute
			  path='/stats/client'
			  component={ClientStats}
			/>
			<Route 
			  path='/edit-document/:token'
			  component={UpdateDocument}
			  exact
			/>

			

             <Route
				path='/chauffeur/transports-details/:id'
				component={SingleTransportDetailsChauffeur}
				exact
			/>
			<Route
				path='/chauffeur/transports/:id/close'
				component={CloseSignature}
				exact
			/>

			<Route
				path='/chauffeur/transports/:id/signaler'
				component={SignalerChauffeurAll}
				exact
			/>
		
			<Route
				path='/client/transports/:id/signaler'
				component={SignalerClientAll}
				exact
			/>
			<Route
			    path='/chauffeur/adresses/:id'
				component={TransportTracking}
				exact
			   />
			<Route
			  path='/chauffeur/mission/:id/single'
			  component={SignalerChauffeurAllMission}
			  exact
			/>
			<Route
				path='/client/transports/:id/single'
                component={SignalerClientAllCommandeMiseADispo}
				exact
			/>
			<Route
			  path='/commerçant/clients'
			  component={ClientList}
			  exact
			/>
			<Route
			  path='/commercial/clients/:id/commandes'
			  component={ListCommande}
			  exactListCommande
			/>
			<Route
			  path='/commercial/clients/:id/accounts'
			  component={ClientAccountForCommercial}
			  exact
			/>
			<Route
			  path='/admin/commerçant/clients/:id/commandes'
			  component={ListCommandes}
			  exact
			/>
			<Route
			  path='/admin/commerçant/comptabilite/:id/month'
			  component={ComptabiliteCommercial}
			  exact
			/>
			<Route
				path='/admin/transports/:id/signaler'
				component={SignalerAdminAll}
				exact
			/>
			<Route
				path='/admin/transports/:id/chauffeur'
				component={TransporteurListChauffeur}
				exact
			/>
			<Route
				path='/admin/chauffeurs'
				component={MesChauffeurs}
				exact
			/>
			<Route
				path='/admin/commerciaux/archived'
				component={MesCommerciauxSupprimer}
				exact
			/>
			<Route
				path='/comptabilite/commercial'
				component={ComptabiliteCommerciaux}
				exact
			/>
			<Route
				path='/admin/comptabilite/commerciaux'
				component={CommerciauxComptabilite}
				exact
			/>
			<Route
				path='/admin/failedChargeDetails/:id'
				component={SingleFailedCharge}
				exact
			/>
			<Route
				path='/admin/administrateurs/new'
				component={NouveauxAdministrateur}
				exact
			/>
			<Route
			path="/admin/commercant/new"
			component={NouveauxCommercant}
			exact
			/>
			
			<Route
			path="/admin/transporters/single/:id"
			component={SingleTransporterDelete}
			exact
			/>
			<Route
			path="/admin/transports-archived/:id/chauffeur"
			component={ListChauffeurForTransporteurDelete}
			exact
			/>
			<Route
			path="/admin/transports-archived/chauffeur/single/:id"
			component={SingleChauffeurDelete}
			exact
			/>
			<Route
			path="/admin/prelevement"
			component={Prelevements}
			exact
			/>
			<Route
			path="/admin/commercial-archived/single/:id"
			component={SingleCommercialDelete}
			exact
			/>
			
			<Route
			path="/admin/clients/single/:id"
			component={SignleClientDelete}
			exact
			/>

			<Route
			path="/admin/commerciaux"
			component={MesCommercant}
			exact
			/>
			<Route
			path="/admin/failedcharge"
			component={FailedCharge}
			exact
			/>
			<Route
			path="/admin/commerçants/:id"
			component={CommercantSingle}
			exact
			/>
			
			<Route
			path="/admin/commerçants/:id/clients"
			component={ListClient}
			exact
			/>
			<Route
				path='/admin/transports/chauffeur/single/:id'
				component={SingleChauffeur}
				exact
			/>
			<Route
				path='/client/card'
				component={CardSetupFormWrapper}
				exact
			/>
			<Route
				path='/commerçant/client/new'
				component={CreateClient}
				exact
			/>
		
			<Route
				path='/transporter/transports/:id/signaler'
				component={SignalerTransporterAll}
				exact
			/>
			<Route
				path='/chauffeur/missions/:placeID/:commandeID/signaler'
				component={SignalerChauffeurAllAdresse}
				exact
			/>
			<PrivateRoute
				path='/client/transports/adresse/:placeID/single'
				component={SignalerClientAllAdresse}
				exact
			/>
			<PrivateRoute
			path='/client/transports/missions/:id'
			component={MissionsClient}
			exact
			/>

           
			<Route
				path='/chauffeur/transports/:id/signaler/new'
				component={NewSignalement}
				exact
			/>
				<Route
				path='/chauffeur/missions/:placeID/:commandeID/signaler/new'
				component={NewSignalementMiseADispo}
				exact
			/>

			<Route
				path='/chauffeur/transports/:commande_id/signaler/:signalement_id/edit'
				component={EditSignalement}
				exact
			/>
			<PrivateRoute
				path='/client/signalement-details/:commande_id/:signalement_id'
				component={DetailsSignalement}
				exact
			/>
			<PrivateRoute
				path='/admin/signalement-details/:signalement_id'
				component={DetailsSignalementAdmin}
				exact
			/>
			<PrivateRoute
				path='/transporter/signalement-details/:signalement_id'
				component={DetialsSignalementTransporteur}
				exact
			/>
			<PrivateRoute
				path='/chauffeur/signalement-details/:signalement_id'
				component={DetailsSignalementChauffeur}
				exact
			/>
			<PrivateRoute
				path='/client/signalement-details/:signalement_id'
				component={DetailsSignalement}
				exact
			/>
			
             <Route
				path='/admin/administrateurs'
				component={MesAdministrateur}
				exact
			/>
			 <Route
				path='/admin/administrateurs/:id'
				component={AdministrateurSingle}
				exact
			/>
			 <Route
				path='/admin/archived'
				component={AdminArchive}
				exact
			/>
			 <Route
				path='/admin/archived/:id'
				component={AdminArchiveSingle}
				exact
			/>
			<Route
			 path='/admin/account'
			 component={CompteAdministrateur}
			 exact
			/>
			<Route
				path='/admin/transports/:commande_id/signaler/:signalement_id/edit'
				component={EditSignalementAdmin}
				exact
			/>

			<Route path='/plateforme' component={Plateforme} exact />
			<Route path='/transports' component={MesTransports} exact />
			<Route
				path='/transports/:id'
				component={DetailsCommandeTransporter}
				exact
			/>
			<Route
				path='/commerçant/:id'
				component={DetailsCommandeCommercant}
				exact
			/>
				<PrivateRoute
				path='/commerçants/account'
				component={CommercantAccount}
				exact
			/>

			<Route path='/chauffeurs/new' component={CreateChauffeur} exact />

			<Route path='/chauffeurs/:id/edit' component={EditChauffeur} exact />
			<Route path='/chauffeurs/:id' component={DetailsChauffeur} exact />


			<PrivateRoute path='/commandes/list' component={CommandesList} exact />
			<PrivateRoute path='/commandes/:id' component={DetailsCommande} exact />
			<Route path='/signin' component={SignIn} />

			<Route path='/login' component={SignInV2} />
			<Route path='/verifyEmail'component={VerfiyEmail}/>

			{/* Test */}
			<Route path='/signup' component={Register} />
			<Route path='/client/signup' component={Register} />
			<Route path='/transporter/signup' component={RegisterTransporter} />
			<Route path='/felicitation/client' component={Felicitation} />
			<Route path='/felicitation/transporter' component={Felicitation} />
			{/* <PrivateRoute path='/Home' component={Home} /> */}
			<Route path='/Felicitation' component={Felicitation} exact />
			<Route path='/CreateAccount' component={CreateAccount} exact />
			<Route
				path='/CreateTransporter'
				component={CreateTransporter}
				exact
			/>

			<Route path='/client/account' component={ClientAccount} />
			<Route path='/transporter/account' component={TransporterAccount} />
			<Route path='/chauffeur/account' component={ChauffeurAccount} />

			<Route path='/admin/clients' component={MesClients} exact />
			<Route path='/admin/clients/supprimés' component={MesClientsSupprimer} exact />

			<Route path='/admin/clients/:id' component={SingleClient} exact />
			<Route
				path='/admin/transporteurs'
				component={MesTransporteurs}
				exact
			/>

			<Route path='/pay' component={PayHop} exact />

			<Route
			    // path='/admin/transporteurs/:id'
				path='/admin/transporters/:id'
				component={SingleTransporter}
				exact
			/>
			<Route
				path='/admin/transporteurs/new'
				component={NouveauxTransporteurs}
				exact
			/>
            <Route
				path='/admin/transporteurs/delete'
				component={MesTransporteursSupprimer}
				exact
			/>
			<Route
				path='/admin/plateforme/commandes'
				component={Commandes}
				exact
			/>

			<Route
				path='/admin/plateforme/commandes/:id'
				component={DetailCommandeAdmin}
				exact
			/>

			<Route
				path='/admin/plateforme/attribuer'
				component={Attribuer}
				exact
			/>

			<Route path='/admin/plateforme/salon' component={Salon} exact />

			<Route
				path='/admin/plateforme/facturation'
				component={Facturation}
				exact
			/>

			<Route
				path='/admin/plateforme/facturation/:id'
				component={SingleClientFacturation}
				exact
			/>

			<Route
				path='/admin/plateforme/anomalie-transport'
				component={AnomalieTransport}
				exact
			/>

			<Route
				path='/admin/comptabilite/clients'
				component={ComptabiliteClients}
				exact
			/>
			<Route
				path='/admin/comptabilite/client/:id'
				component={ComptabiliteSingleClient}
				exact
			/>

			<Route
				path='/admin/comptabilite/transporteurs'
				component={ComptabiliteTransporteurs}
				exact
			/>
			<Route
				path='/admin/comptabilite/transporteur/:id'
				component={ComptabiliteSingleTransporteur}
				exact
			/>

			<Route
				path='/passer-commande'
				component={PublicPasserCommande}
				exact
			/>
			<Route
				path='/forgot'
				component={ForgotPassword}
				exact
			/>
			<Route
				path='/resetpassword/:id'
				component={ResetPassword}
				exact
			/>

			<Route
				path='/comptabilite/client/day'
				component={ComptabiliteByDay}
				exact
			/>

            <Route
			  path="/client/prelevement"
			  component={Prelevement}
			/>
			<Route
			  path="/client/prelevement-detail/:id"
			  component={PrelevementDetails}
			  exact
			/>
			<Route
				path='/comptabilite/client/month'
				component={ComptabiliteByMonth}
				exact
			/>

			<Route
				path='/comptabilite/transporter/day'
				component={ComptabiliteByDayTransporter}
				exact
			/>

			<Route
				path='/comptabilite/transporter/month'
				component={ComptabiliteByMonthTransporter}
				exact
			/>

			<Route path='/historique/client' component={Historique} exact />
			<Route
				path='/historique/transporter'
				component={HistoriqueTransporter}
				exact
			/>
			<Route
				path='/annulées/transporter'
				component={CommandeAnnuléesTransporter}
				exact
			/>
			<Route
				path='/annulées/chauffeur'
				component={CommandeAnnuléesChauffeur}
				exact
			/>
			<Route
			path='/annulees/admin'
			component={CommandeAnnulles}
			exact
		    />
			<Route
			path='/terminer/admin'
			component={Terminer}
			exact
		    />
            <Route
				path='/annulées/client'
				component={CommandeAnnulées}
				exact
			/>
			<Route
			path="/admin/demande-transfert/:id"
			component={DemandeTransfert}
			exact
			/>
			<Route
			path="/admin/demande-transfert/details/:id"
			component={DetailsDemandeTransfert}
			exact
			/>
			


			<Route path='/tarif' component={Tarif} exact />

			<Route path='/map' component={Map} exact />

			<Route component={NotFound} />
		</Switch>
	)
}

export default Routes
